@import '~antd/dist/antd.css';

html,body {
  color: #333;
  min-width: 1200px;
}

:focus {
  outline: 0;
}

button {
  cursor: pointer;
}

.context {
  margin: auto;
  padding: 0 20px;
  max-width: 1400px;
  min-width: 1200px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input {
  box-shadow: none !important;
}

p {
  margin: 0;
}

/* ***** 地址选择器样式 ***** */
.area-select:hover {
  border-color: #40a9ff !important;
}

.area-selectable-list .area-select-option.selected {
  color: #000 !important;
  background-color: #e7f7ff !important;
}
/* ****** end ****** */
